import React from "react";
import Layout from "../components/organisms/layout/layout";
import CustomContactTemplate from "../components/templates/custom-contact-template/customContactTemplate";

const CustomContact = () => {
  return (
    <Layout title="Dicar | Contacto producto personalizado">
      <CustomContactTemplate />
    </Layout>
  )
}

export default CustomContact;