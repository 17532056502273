import React from "react";
import { useStaticQuery, graphql } from 'gatsby';
import { Formik, Form } from 'formik';
import emailjs from "emailjs-com";

import "./customContactTemplate.scss";

const inputs = [
  [
    { name: "name", label: "Nombre", type: "text" },
    { name: "phone", label: "Teléfono", type: "text" },
  ],
  [
    { name: "lastname", label: "Apellido", type: "text" },
    { name: "email", label: "Dirección de correo electrónico", type: "email" },
  ]
];

const CustomContactTemplate = (props) => {
  const data = useStaticQuery(graphql`
    query QUERY_CONTACT_CUSTOM_PRODUCTS {
      allContentfulContactoProductosPersonalizados {
        nodes {
          buttonText
          pageTitle
          productTypes
        }
      }
    }
  `);

  const info = data.allContentfulContactoProductosPersonalizados.nodes[0];

  const {
    buttonText,
    pageTitle,
    productTypes
  } = info;

  const initialValues = {
    name: "",
    lastname: "",
    company: "",
    phone: "",
    product: "",
    comments: ""
  }

  const sendEmail = (data) => {
    emailjs.send("service_adtee3e", "template_se4e0nq", data, "user_bGG8uZHY1QhC8YtGooeEO")
      .then((res) => {
        if (res.status === 200) {
          alert("Mensaje enviado correctamente. Pronto nos pondremos en contacto contigo.");
          window.location.reload();
        }
      })
      .catch(() => {
        alert("Hubo un error en el envío del mensaje, por favor inténtalo más tarde.");
        window.location.reload();
      })
  }

  return (
    <div className="t-custom-contact">
      <div className="t-custom-contact__content">
        <h1 className="t-custom-contact__content__title">{pageTitle}</h1>
        <Formik
          initialValues={initialValues}
          onSubmit={values => {
            sendEmail(values);
          }}
        >
          {({ values, handleChange }) => (
            <Form className="t-custom-contact__content__form">
              <div className="t-custom-contact__content__form__inputs">
                {inputs.map((column, i) => (
                  <div key={i} className="t-custom-contact__content__form__column">
                    {column.map((input) => (
                      <div key={input.name} className="t-custom-contact__content__form__field">
                        <label htmlFor={input.name}>
                          {input.label} <span>*</span>
                        </label>
                        <input
                          id={input.name}
                          name={input.name}
                          type={input.type}
                          value={values[input.name]}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    ))}
                  </div>
                ))}
                <div className="t-custom-contact__content__form__column">
                  <div className="t-custom-contact__content__form__field">
                    <label
                      htmlFor="company"
                      aria-label="Nombre de compañía"
                    >
                      Nombre de compañía <span>*</span>
                    </label>
                    <input 
                      type="text" 
                      name="company" 
                      id="company" 
                      required
                      onChange={handleChange}
                    />
                  </div>
                  <div className="t-custom-contact__content__form__field">
                    <label
                      htmlFor="product"
                      aria-label="Seleccione el producto a personalizar"
                    >
                      Seleccione el producto a personalizar <span>*</span>
                    </label>
                    <select
                      id="product"
                      name="product"
                      value={values.product}
                      onChange={handleChange}
                      required
                    >
                      <option
                        value=""
                        label="- Seleccionar -"
                        aria-label="- Seleccionar -"
                      />
                      {productTypes.map(item => (
                        <option
                          key={item}
                          value={item}
                          label={item}
                          aria-label={item}
                        />
                      ))}
                    </select>
                  </div>

                </div>
              </div>
              <div className="t-custom-contact__content__form__field">
                <label
                  htmlFor="comments"
                  aria-label="Describe tu solicitud"
                >
                  Describe tu solicitud
                </label>
                <textarea
                  id="comments"
                  name="comments"
                  value={values.comments}
                  onChange={handleChange}
                  rows={6}
                />
              </div>
              <button
                className="t-custom-contact__content__form__submit"
                type="submit"
              >
                {buttonText}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default CustomContactTemplate;

